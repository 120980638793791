.App {
	text-align: center;
	height: 100vh;
	width: 100vw;

	#graph {
		height: 100vh;
		width: 100vw;
	}

	#home {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;

		width: 12em;
		height: max-content;
		border-radius: 5px;
		transition: 0.25s ease-in;

		border: 1px solid rgba($color: #001219, $alpha: 0.2);
		color: rgba($color: #001219, $alpha: 0.8);
		background: linear-gradient(0deg, hsla(298, 68%, 90%, .25) 0%, hsla(30, 82%, 91%, 1) 100%);
		// background: linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%)
		// background: linear-gradient(0deg, hsla(332, 53%, 82%, 0.2) 10%, hsla(176, 57%, 89%, 1) 100%);

		p {
			position: relative;
			z-index: 1;
			mix-blend-mode: exclusion;
		}

		&::before {
			display: none;
			content: '';
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			align-items: center;
			justify-content: center;
			align-self: flex-start;
			width: 1%;
			height: 100%;
			background-color: transparent;
			transition: width 0.5s ease-in-out;
		}

		&:hover {
			border: 1px solid rgba($color: #000000, $alpha: 0.5);
			color: transparent;
			padding: 1px;
			color: white;
			// p {
			// 	color: white;
			// }

			&::before {
				display: flex;
				background-color: rgba(#023047, 1);
				width: 100%;
			}
		}


	}
}